.App-logo {
  padding-top: 1rem;
  height: 30vmin;
}

.App-header h1 {
  color: #9505E3;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Estilos para la imagen */
.img-inst {
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
  display: inline;

}


.cont-inst {
  font-display: flex;
  padding: 5px;
  border-radius: 5px;
  align-items: center;

  justify-content: space-around;
  background-color: #2980b9;

}

.link {
  text-decoration: none;
  color: white;
}

.cont-inst h6 {

  display: inline;

}